import {
  Header,
  Container,
  Title,
  SubTitle,
  Images,
  ImagesItem,
  Content,
} from "../components/styles";
import * as React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data
import Accordion from "../components/accordion";
import Pattern from "../images/cake.png";
import SEO from "../components/seo";

const HeaderCustom = styled((props) => <Header {...props} />)`
  &:after {
    background-image: url(${Pattern});
  }
`;

// markup
const CakesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "cake" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <SEO
        title="Celebration Cakes in Rugby"
        description="A variety of celebration cakes available to order in Rugby for birthdays, weddings or any other special occasion. "
      />
      <HeaderCustom>
        <Container>
          <Title>Celebration Cakes</Title>
        </Container>
      </HeaderCustom>
      <Container>
        <Content>
          <div>
            <SubTitle>Flavours</SubTitle>
            <Accordion title="Vanilla &amp; Strawberry" />
            <Accordion title="Lemon &amp; Lemon Curd" />
            <Accordion title="Chocolate Orange" />
            <Accordion title="Salted Caramel" />
            <Accordion title="Chocolate" />
            <Accordion title="White Chocolate &amp; Raspberry" />
            <Accordion title="Black Forest" />
            <Accordion title="Carrot Cake" />
          </div>
          <div>
            <SubTitle>Prices</SubTitle>
            <p>
              6” from £50{" "}
              <small style={{ fontSize: "0.7em" }}>
                (up to 24 party slices)
              </small>
              <br />
              8” from £65{" "}
              <small style={{ fontSize: "0.7em" }}>
                (up to 48 party slices)
              </small>
            </p>
            <p>
              Price includes the base and box for your cake, 4 layers of
              delicious sponge and decoration using buttercream.
            </p>
            <p>
              Quotes for tiered cakes on request. Additional charges for
              homemade macarons, cake toppers &amp; flowers will apply.
            </p>
          </div>
        </Content>
        <Images>
          {data.allFile.edges.map((image) => (
            <ImagesItem
              fluid={image.node.childImageSharp.fluid}
              alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
              key={image.node.base.split(".")[0]}
            />
          ))}
        </Images>
      </Container>
    </React.Fragment>
  );
};

export default CakesPage;
